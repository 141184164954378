//Entire skills section
.skills {
  padding: 9rem 10rem;
  background-image: linear-gradient(
    to bottom right,
    $color-secondary-light,
    $color-secondary-dark
  );

  @include responsive(tab-land) {
    padding: 9rem 4rem;
  }

  @include responsive(tab-port) {
    padding: 9rem 2rem;
  }

  @include responsive(tab-port) {
    padding: 9rem 4rem;
  }
}

//Holds h4 and 3 card components
.container__skills {
  margin: 0 auto;
  width: 90rem;
  //In case screen is less than 90 rem
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include responsive(tab-land) {
    width: 95%;
    justify-content: center;
  }

  @include responsive(tab-port) {
    width: 100%;
    // justify-content: space-around;
    // padding-left:3rem;
    // padding-right:3rem;
  }

  @include responsive(phone-land) {
    flex-direction:column;
    align-items: center;
    padding:0 5%;
  }

  @include responsive(phone-port) {
    padding:0;
  }




  //skills section subheader
  .heading-4 {
      font-size: 2.5rem;
      font-weight: 300;
      width:100%;

      span {
        display: inline-block;
          margin-top:1rem;
          font-style: italic;
      }
  }
}
