.skills {
  &__block {
    background-color: $color-accent-1;
    width: 27rem;
    padding: 4rem;
    text-align: center;
    box-shadow: 0 10px 6px -6px $color-gray-2;

    @include responsive(tab-port) {
      padding: 4rem 3rem;
      width: 32%;
    }

    @include responsive(phone-land) {
      width: 100%;
      min-width: 35rem;
      max-width: 100%;
      margin: 3rem 0 0 0;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    @include responsive(phone-land) {

      min-width: 100%;
 
    }

    //middle skills block:
    &--center {
      @include responsive(tab-land) {
        margin: 0 1.3rem;
      }

      @include responsive(phone-land) {
        margin: 3rem 0 0 0;
      }
    }
  }

  //Inside each block
  &__logo {
    height: 4rem;
    width: 4rem;
    fill: $color-secondary;
  }

  &__list {
    list-style-type: none;

  }
}

//Individual list item
.skill {
  padding: 2rem 0;
  font-size: 2.2rem;

  &:not(:last-child) {
    border-bottom: 1px solid $color-gray-1;
  }
}
