.footer {
  background-color: $color-accent-4;
  display: flex;
  justify-content: space-evenly;
  color: $color-accent-1;
  padding: 3.5rem 20rem;

  @include responsive(tab-land) {
    padding: 3rem 15rem;
  }

  @include responsive(tab-port) {
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  @include responsive(phone-land) {
    padding: 2.5rem 8rem;
  }

  @include responsive(phone-port) {
    padding: 2.5rem 6rem;
  }

  &__text {
    font-size: 1.4rem;

    //Move text below the links
    @include responsive(tab-port) {
      order: 2;
      text-align: center;
      font-size: 1.6rem;
    }
  }

  &__links {
    list-style-type: none;

    @include responsive(tab-port) {
      margin-bottom: 0.3rem;
      
    }
  }

  //Individual link within list
  &__link {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;

    a {
      font-size: 1.4rem;

      @include responsive(tab-port) {
        font-size: 1.6rem;
      }
    }
  }

  &__link:not(:last-child) {
    border-right: solid 2px $color-accent-1;
  }
}
