//h1
.heading-1 {
	font-family: $font-title;
	font-weight: 100;
	font-size: 18rem;
	line-height: 1;
	color: $color-accent-4;
	text-shadow: 0 0.7rem 0.8rem rgba($color-gray-2, 0.5);
	margin-bottom: -1.5rem;
	padding: 1rem 1rem 0rem 1rem;
	@include responsive(tab-port) {
		font-size: 17rem;
	}

	@include responsive(phone-land) {
		font-size: 14rem;
	}

	@include responsive(phone-port) {
		font-size: 12rem;
	}
}

//h2 - used for each section
.heading-2 {
	font-family: $font-title;
	font-size: 12rem;
	font-weight: 300;
	line-height: 0.75;

	@include responsive(phone-port) {
		font-size: 10rem;
	}

	//Used for 'about' header sentence
	&--plain {
		font-family: $font-text;
		font-size: 6rem;
		line-height: 1.3;

		@include responsive(tab-port) {
			font-size: 5rem;
			line-height: 1.2;
		}

		@include responsive(phone-land) {
			font-size: 4rem;
		}
  }
  
	&--light {
		color: $color-accent-2;
	}
	&--dark-1 {
		color: $color-primary;
	}
	&--dark-2 {
		color: $color-secondary;
	}
	&--dark-3 {
		color: $color-tertiary;
	}
	&--dark-4 {
		color: $color-accent-4;
	}
}

//Used for subtext in heading
.heading-3 {
	font-family: $font-text;
	font-size: 3.5rem;
	line-height: 1.5;
	font-weight: 400;

	@include responsive(phone-land) {
		font-size: 3rem;
	}
	@include responsive(phone-port) {
		font-size: 2.2rem;
	}
	&--light {
		color: $color-accent-2;
	}
	&--dark-1 {
		color: $color-primary;
	}
	&--dark-2 {
		color: $color-secondary;
	}
	&--dark-3 {
		color: $color-tertiary;
	}
	&--dark-4 {
		color: $color-accent-4;
	}
}

//Used for project titles
.heading-4 {
	font-family: $font-text;
	line-height: 1.3;
	font-weight: 400;
	font-size: 3rem;
	&--light {
		color: $color-accent-2;
	}
	&--light-2 {
		color: $color-accent-3;
	}
	&--dark-1 {
		color: $color-primary;
	}
	&--dark-2 {
		color: $color-secondary;
	}
	&--dark-3 {
		color: $color-tertiary;
	}
	&--dark-4 {
		color: $color-accent-4;
	}
}

.paragraph {
	line-height: 1.5;
	margin-bottom: 1rem;
	font-size: 2rem;
	font-weight: 300;
}

/* ---------------- BUTTONS ------------  */
.btn {
	border: none;
	display: inline-block;
	padding: 1.2rem 0.5rem;
	width: 20rem;
	font-size: 2rem;
	color: $color-accent-2;
	box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
	transition: all 0.2s;
	text-align: center;
	text-decoration: none;
	font-weight: 300;

	@include responsive(phone-port) {
		width: 32rem;
		padding: 1.5rem 0.5rem;
		font-size: 2.25rem;
	}

	&:hover {
		filter: brightness(90%);
	}

	&:active {
		transform: translateY(3px);
	}

	&:focus {
		outline-color: currentColor;
	}

	&--color-1 {
		background-color: $color-primary;
	}

	&--color-2 {
		background-color: $color-secondary;
	}

	&--color-2-dark {
		background-color: $color-accent-4;
	}

	&--color-3 {
		background-color: $color-tertiary;
	}

	&--color-4 {
		background-color: $color-accent-2;
	}

	&--color-5 {
		background-color: $color-accent-3;
	}
}

//Nav elements
.btn-link {
	//required for transition to work
	display: inline-block;
	font-size: 2rem;
	font-family: $font-text;
	font-weight: 400;
	color: $color-tertiary;
	text-decoration: none;
	transition: all 0.3s;

	&:hover {
		filter: brightness(120%);
	}

	&:active {
		transform: translateY(3px);
	}

	&:focus {
		outline: none;
		border-bottom: 2px solid currentColor;
	}
}

/* ---------------- LISTS ------------  */

//List with all list items horizontal (i.e. navbar and project tech)
.inline-list {
	list-style-type: none;
	display: flex;
	align-items: center;
	font-size: 2rem;

	li {
		padding: 0rem 1.75rem;

		@include responsive(phone-port) {
      padding: 0rem .75rem;

		}
	}

  //For full screen, don't add border on last list item of second list (all on one line)
  //And don't add to last list item if there is only one list. 
	//Overrides next border-right style due to specificity
  &--border:nth-of-type(2) > li:last-child,
  &--border:only-child > li:last-child {
		border-right: none;
	}

	//If a border list and screen full width, put a vertical line between each child
	&--border li {
		border-right: solid 2px currentColor;
    padding: 0rem 1.5rem;
		/*Once the screen is too small to accomadate multiple ul on one row, list items are split 
    into two rows with span divider between top/bottom ul. Where to put vertical dividers depends
    on number of list items:  
	
   A.   1 | 2 | 3 - 3 list items in ul, put borders on both sides of middle list item 
    
   B.    1 | 2     - 2 list items in ul, put borders to the left of 2

   C.   | 1 |    - 1 list items in ul, put borders on both sides  
    
    */

		@include responsive(phone-land) {
			//to override all previous borders
			border: none;
			// Option A: Select the middle list item,
			//Option C Select list item that is first and last (only one) and place borders on either side
			&:nth-child(2):not(:last-child),
			&:last-child:nth-child(1) {
				border-left: solid 2px currentColor;
        border-right: solid 2px currentColor;
        margin: 0 auto;
			}

			//Option B: Select the second list item when it is the last one (2 of 2) and put a border to the left only
			&:last-child:nth-child(2) {
				border-left: solid 2px currentColor;
			}
		}
	}
}
