//Entire about section
.about {
  background-color: $color-accent-2;
  padding: 8rem 0 8rem 0;

  //Contains all section elements
  &__container {
    margin: 0 auto;
    width: 85rem;
    max-width: 100%;

    @include responsive(tab-port) {
      width: 100%;
      padding: 0rem 12rem;
    }

    @include responsive(phone-land) {
      width: 100%;
      max-width:100rem;
      padding: 0rem 10rem;
    }

    @include responsive(phone-port) {
      padding: 0rem 3rem;
    }
  }

  //large header
  h2 {
    font-weight: 100;
    //Adds breakpoints in header

    @include responsive(phone-land) {
      padding-right: 3%;
    }

    @include responsive(phone-port) {
      padding-right: 0%;
    }

    @include responsive(big-desktop) {
      width: 80%;
    }

    //Adds breakpoints in about section header

      .heading__bp--1,
      .heading__bp--2 {        
        display: block;

        @include responsive(phone-land) {
          display: inline-block;
        }
      }  
    }

    //about paragraphs
    &__text {
      columns: 2;
      column-gap: 2rem;

      @include responsive(tab-port) {
        columns: 1;
        column-gap:0;
      }
    }
  }

