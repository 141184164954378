@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Noto+Sans+KR:wght@100;300;400&display=fallback");

//------------------COLORS ------------------//

//-----------orange---------------

$color-primary: #F05D23;
// $color-primary-light: #fb8e5b;
$color-primary-light: #ff6e34fb;


// ------------ blue --------------

$color-secondary: #1f98a1;
$color-secondary-light: #44b6be;

// $color-secondary-light: #90c9cc;
$color-secondary-dark: #156565;

// ------------ burgandy --------------

$color-tertiary: #b44161;
$color-tertiary-light: #c55776;
$color-tertiary-dark: #782B41;

//------------Accents---------------------------
$color-accent-1: #f7f9f9; //white
$color-accent-2: #f0f0e6;  //light cream
$color-accent-3: #d1d1b3; //khaki
$color-accent-4: #003C66; //navy blue


$color-gray-1:#d6d6d6; //light gray
$color-gray-2:#383838; //dark gray

//---FONTS---//

$font-title: 'Great Vibes', Garamond, cursive;
$font-text: 'Noto Sans KR', sans-serif;

