%project {
	padding: 6rem 3rem;

	@include responsive(tab-port) {
		padding: 10rem 3rem;
	}

	@include responsive(phone-port) {
		padding: 8rem 2rem 10rem 2rem;
	}
}

.project {
	&--bg-1 {
		//Contains the header of the entire section
		@extend %project;
		background-image: linear-gradient(
			to bottom right,
			$color-tertiary 0%,
			$color-tertiary 40%,
			$color-tertiary-dark 100%
		);
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 10rem;

		//Add additional margin just on the first project
		& .project__container {
			@include responsive(tab-port) {
				margin-top: 2rem;
			}
		}
	}

	&--bg-2 {
		@extend %project;
		background-image: linear-gradient(
			to bottom right,
			$color-accent-2 0%,
			$color-accent-2 40%,
			$color-accent-3 100%
		);
	}

	&--bg-3 {
		@extend %project;
		background-image: linear-gradient(to bottom right, #2579b6 0%, $color-accent-4 40%, $color-accent-4 100%);
	}

	&--bg-4 {
		@extend %project;
		background-image: linear-gradient(
			to bottom right,
			$color-accent-2 0%,
			$color-accent-2 40%,
			$color-accent-3 100%
		);
	}

	&--bg-5 {
		@extend %project;
		background-image: linear-gradient(
			to bottom right,
			$color-primary-light 0%,
			$color-primary 40%,
			$color-primary 100%
		);
		//add additional bottom padding just on last project
		padding-bottom: 10rem;
	}

	//Nested inside each project section. Contains image, h4,  ul, p, and btn group
	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 55%;
		margin: 0 auto;

		@include responsive(tab-land) {
			width: 70%;
		}

		@include responsive(tab-port) {
			width: 85%;
		}
		@include responsive(phone-land) {
			width: 100%;
		}

		//project text
		p {
			width: 70%;

			@include responsive(tab-land) {
				width: 75%;
			}

			@include responsive(phone-port) {
				width: 80%;
			}
		}
	}

	&__image-container {
		width: 100%;

		@include responsive(big-desktop) {
			width: 85%;
		}
	}

	&__image {
		width: 100%;
	}

	&__title {
		@include responsive(phone-port) {
			margin: 4rem 0 2.5rem 0;
			font-size: 3.5rem;
		}
	}

	&__skills {
		//Contains top and bottom skills list and a span in between for a horizontal line
		&__container {
			display: flex;
			justify-content: center;
			align-content: space-between;

			//stacks skill lists
			@include responsive(phone-land) {
				flex-direction: column;
				width: 100%;
				padding: 0 1rem;
			}
		}

		//1 -2 ul separated by span on smaller screens
		&__list {
			@include responsive(phone-land) {
				width: 80%;
				text-align: center;
				padding: 0;
				margin: 0 auto;
			}

			@include responsive(phone-port) {
				width: 100%;
			}
		}

		&__line {
			display: none;
			//only display once skill lists will be stacked.
			@include responsive(phone-land) {
				display: inline-block;
				width: 80%;
				height: 2px;
				background-color: currentColor;
				margin: 0 auto 1rem;
			}

			@include responsive(phone-port) {
				width: 70%;
			}
		}
	}

	//individual li contains technology used
	&__tech {
		@include responsive(phone-land) {
			font-size: 2rem;
			padding: 1rem 0;
			flex: 0 0 33%;
			margin-bottom: 1rem;
		}
	}

	//container for code and web page buttons
	&__btn-group {
		margin-top: 2rem;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-content: space-between;

		@include responsive(phone-land) {
			order: 2;
		}

		//View Site and View Code buttons
		a {
			//Centers text and icon within button
			display: flex;
			justify-content: center;
			align-items: center;
			&:not(:last-child) {
				margin-right: 2rem;

				@include responsive(phone-port) {
					//Adds additional padding due to slighly larger icon in other button
					padding: 1.625rem 0.5rem;
					margin: 0 0 1.5rem 0;
				}
			}
		}
	}

	//styles the svgs within the buttons
	&__link-logo {
		height: 2.5rem;
		width: 2.5rem;
		margin-right: 1rem;
		fill: currentColor;
	}

	&__code-logo {
		height: 3rem;
		width: 3rem;
		margin-right: 1rem;
		fill: currentColor;
	}
}
