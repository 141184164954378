//MARGINS

.u-margin-bottom-xl {
  margin-bottom: 10rem;
}
.u-margin-bottom-lg {
  margin-bottom: 8rem;
}

.u-margin-bottom-md {
  margin-bottom: 6rem;
}

.u-margin-bottom-sm {
  margin-bottom: 4rem;

  @include responsive(tab-port) {
    margin-bottom:2rem;
  }
}

.u-margin-bottom-xs {
  margin-bottom: 2rem;

  @include responsive(tab-port) {
    margin-bottom:1rem;
  }
}


.center-text {
  text-align: center;
}

.justify-text {
  text-align: justify;
}

// FONT COLOR

.primary {
  color: $color-primary;
}

.primary-light-1 {
  color: $color-primary-light;
}


.secondary {
  color: $color-secondary;
}

.secondary-light {
  color: $color-secondary-light;
}
.secondary-dark-1 {
  color: $color-secondary-dark;
}

.secondary-dark-2 {
  color: $color-accent-4;
}

.tertiary {
  color: $color-tertiary;
}

.light {
  color: $color-accent-1;
}

.light-2 {
  color: $color-accent-2;
}